import React, { useMemo } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { Link } from '../link/link'
import {
  StyledCardDesignImageContainer,
  StyledCarouselItemContainer,
  StyledDate,
  StyledDescription,
  StyledImage,
  StyledLinkTitle,
  StyledTitle,
} from './styledCardDesignCarousel'

// Image
export type CardDesignImageProps = React.ComponentProps<typeof StyledImage> & {
  src?: string
}

export const CardDesignImage: React.FC<CardDesignImageProps> = ({ src, ...props }) => {
  return (
    <StyledCardDesignImageContainer>
      {src && (
        <StyledImage src={src} {...props} />
      )}
    </StyledCardDesignImageContainer>
  )
}

// Date string
export type CardDesignDateProps = React.ComponentProps<typeof StyledDate> & {
  //
}

export const CardDesignDate: React.FC<CardDesignDateProps> = ({ href, children, ...props }) => (
  <StyledDate {...props}>
    {children}
  </StyledDate>
)

// Link title
export type CardDesignTitleProps = React.ComponentProps<typeof StyledLinkTitle> & {
  titleVariant?: 'h3' | 'body1'
}

export const CardDesignTitle: React.FC<CardDesignTitleProps> = ({ titleVariant, children, ...props }) => (
  <StyledTitle variant={titleVariant} {...props}>
    {children}
  </StyledTitle>
)

// Description
export type CardDesignDescriptionProps = React.ComponentProps<typeof StyledDescription> & {
  
}

export const CardDesignDescription: React.FC<CardDesignDescriptionProps> = ({ href, children, ...props }) => (
  <StyledDescription {...props}>
    {children}
  </StyledDescription>
)

// Item container
export type CardDesignItemContainerProps = React.ComponentProps<typeof StyledCarouselItemContainer> & {
  href?: string
}

export const CardDesignItemContainer: React.FC<CardDesignItemContainerProps> = ({ href, ...props }) => (
  <StyledCarouselItemContainer {...props} container justifyContent="flex-start" alignItems="center">
    <Link href={href || '#'} passHref>
      <StyledLinkTitle {...props}>
        {props.children}
      </StyledLinkTitle>
    </Link>
  </StyledCarouselItemContainer>
)

export type CarouselItemProps = ({ image?: boolean; title?: boolean; date?: boolean; description?: boolean }) & (
  // Image
  | ({ image: true } & CardDesignImageProps)
  // Title
  | ({ title: true } & CardDesignTitleProps)
  // Date
  | ({ date: true } & CardDesignDateProps)
  // Description
  | ({ description: true } & CardDesignDescriptionProps)
  // Item container
  | CardDesignItemContainerProps
)

export const CardDesignItem: React.FC<CarouselItemProps> = ({ image, title, date, description, children, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const itemPosition = useMemo(() => {
    return isMobile ? 'center' : 'flex-start'
  }, [isMobile])

  const titleVariant = useMemo(() => {
    return isMobile ? 'body1' : 'h3'
  }, [isMobile])

  if (image) {
    return (
      <CardDesignImage {...props} />
    )
  }

  if (date) {
    return (
      <CardDesignDate {...props} variant="body1">
        {children}
      </CardDesignDate>
    )
  }

  if (title) {
    return (
      <CardDesignTitle {...props} titleVariant={titleVariant}>
        {children}
      </CardDesignTitle>
    )
  }

  if (description) {
    return (
      <CardDesignDescription {...props}>
        {children}
      </CardDesignDescription>
    )
  }

  return (
    <CardDesignItemContainer {...props}
      container
      alignItems={itemPosition}
      justifyContent={itemPosition}>
      {children}
    </CardDesignItemContainer>
  )
}